import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash', // 使用 hash 模式
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/index',
      component: () => import('../views/homePage.vue'),
      children: [
        {
          path: 'index',
          name: 'index',
          component: () => import('../views/homeIndex.vue')
        },
        {
          path: 'detail',
          name: 'detail',
          component: () => import('../views/detailModel.vue')
        },
        {
          path: 'supplyDetail',
          name: 'supplyDetail',
          component: () => import('../views/supplyDetail.vue')
        },
        {
          path: 'fundsDetail',
          name: 'fundsDetail',
          component: () => import('../views/fundsDetail.vue')
        }, {
          path: 'brandDetail',
          name: 'brandDetail',
          component: () => import('../views/brandDetail.vue')
        }, {
          path: 'industryDetail',
          name: 'industryDetail',
          component: () => import('../views/industryDetail.vue')
        }, {
          path: 'planDetail',
          name: 'planDetail',
          component: () => import('../views/planDetail.vue')
        }, {
          path: 'newsDetail',
          name: 'newsDetail',
          component: () => import('../views/newsDetail.vue')
        }, {
          path: 'expertDetail',
          name: 'expertDetail',
          component: () => import('../views/expertDetail.vue')
        },
        {
          path: 'ProjectOwner',
          name: 'ProjectOwner',
          component: () => import('../views/ProjectOwner.vue')
        }, {
          path: 'FunderDocs',
          name: 'FunderDocs',
          component: () => import('../views/FunderDocs.vue')
        },
        {
          path: 'SupplierList',
          name: 'SupplierList',
          component: () => import('../views/SupplierList.vue')
        },
        {
          path: 'brandBuilder',
          name: 'brandBuilder',
          component: () => import('../views/brandBuilder.vue')
        },
        {
          path: 'videoHub',
          name: 'videoHub',
          component: () => import('../views/videoHub.vue')
        },
        {
          path: 'playVideo',
          name: 'playVideo',
          component: () => import('../views/playVideo.vue')
        },
        {
          path: 'industryAttraction',
          name: 'industryAttraction',
          component: () => import('../views/industryAttraction.vue')
        },
        {
          path: 'expert',
          name: 'expert',
          component: () => import('../views/expErt.vue')
        },
        {
          path: 'Soln',
          name: 'Soln',
          component: () => import('../views/solnPage.vue')
        },
        {
          path: 'indusNews',
          name: 'indusNews',
          component: () => import('../views/indusNews.vue')
        },
        {
          path: 'VendorSetup',
          name: 'VendorSetup',
          component: () => import('../views/VendorSetup.vue')
        },
        {
          path: 'pubLish',
          name: 'pubLish',
          component: () => import('../views/pubLish.vue'),
        },
        {
          path: 'sendJob',
          name: 'sendJob',
          component: () => import('../views/sendJob.vue'),
        },
        {
          path: 'delivery',
          name: 'delivery',
          component: () => import('../views/deliVery.vue'),
        },
        {
          path: 'userInfo',
          name: 'userInfo',
          redirect: '/userInfo/profile',
          component: () => import('../views/userInfo.vue'),
          children: [
            {
              path: 'profile',
              name: 'profile',
              component: () => import('../views/proFile.vue'),
            },
            {
              path: 'message',
              name: 'message',
              component: () => import('../views/messAge.vue'),
            },
            {
              path: 'collect',
              name: 'collect',
              component: () => import('../views/collEct.vue'),
            },
            {
              path: 'talent',
              name: 'talent',
              component: () => import('../views/talentSub.vue'),
              children:[
               
              ]
            },
           
            {
              path: 'MyRelease',
              name: 'MyRelease',
              component: () => import('../views/MyRelease.vue'),
            },
            {
              path: 'promotion',
              name: 'promotion',
              component: () => import('../views/promoTion.vue'),
            },
            {
              path: 'setTings',
              name: 'setTings',
              component: () => import('../views/setTings.vue'),
            },
           
          ]
        },
      ]
    },


  ]
});

export default router;
