<template>
  <div>
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App',
}
</script>

<style lang="scss">
  *{
    margin: 0;
    padding: 0;
  }
  .bannerItem{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
    height: 100%;
    }
  }
  body{
    background: #F5FAF9;
  }
  .popupBox {
  background-image: url("./image/bg2.png");
  // background-color: yellowgreen;
  width: 344px;
  height: 344px;
  background-size: cover;
  border-radius: 10px;
  text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  img {
    width: 209.42px;
    height: 209.42px;
    text-align: center;
  }
}
.flex1{
  display: flex;
}
.fcenter{
  align-items: center;
}
.ftop{
  align-items: baseline;
}
</style>
