import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element);
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
// Vue.use(Element, { size: 'small', zIndex: 3000 });
// import { Carousel } from 'element-ui';
// Vue.use(Carousel);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
